import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueResource from 'vue-resource';
import { VueReCaptcha } from 'vue-recaptcha-v3'

import Logo from "./components/Logo";
import Social from "./components/Social";

import { store } from "./store/index";
import router from './router'

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_KEY })

Vue.component("site-logo", Logo);
Vue.component("social-component", Social);

new Vue({
    store,
    vuetify,
    router,
    render: (h) => h(App)
}).$mount("#app");
