<template>
    <div class="custom-title text" style="margin-top: 40px !important">
        <div style="color: red; font-variant-caps: normal;">Follow Us</div>
        <div style=" display: flex; justify-content: center; margin-top: 20px;">
            <a href="https://twitter.com/LegendaoNFT" target="_"><img class="social-img" src="@/assets/images/twitter.svg"  /></a>
            <a href="https://discord.com/invite/FU35esT2nD" target="_"><img class="social-img" src="@/assets/images/discord.svg"  /></a>
        </div>
    </div>
</template>

<style scoped>
@media (min-width: 700px) {
    .text {
        font-size: 25px !important; 
        margin-top: 20px;
        letter-spacing: 5px; 
    }

    .social-img {
        width: 30px;
        height: 30px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
}

@media (max-width: 500px) {
    .text {
        font-size: 20px !important; 
        font-weight: bold;
        margin-top: 20px;
        letter-spacing: 5px; 
    }

    .social-img {
        width: 25px;
        height: 25px;
        margin-left: 25px;
        margin-right: 25px;
    }

}
</style>

<script>
export default {
    name: "social-component",

    data: () => ({}),
};
</script>
