<template>
    <v-container style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <site-logo />
            <div class="custom-title" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 50px">
                <div class="line1" >Kevin Smith</div>
                <div class="line2" style="color: red;">KILLROY WAS HERE</div>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <div v-if="loading">Detecting your wallet address...</div>
                <template v-if="error != ''">
                    <v-icon color="orange" x-large
                        >mdi-alert-circle-outline</v-icon
                    >
                    <div v-html="error" style="margin-bottom: 20px; text-align: center"></div>
                    <iframe
                        :width="playerWidth"
                        :height="playerWidth / (16.0 / 9.0)"
                        src="https://www.youtube.com/embed/GarnfDNKPLg"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </template>

                <template v-if="error == '' && walletAddress != '' && !showThanks">
                    <div class="kevin-font">
                        <v-icon>mdi-wallet</v-icon> <span style="margin-left: 10px">{{ shotAddress }}</span>
                    </div>
                    <div style="width: 90%; margin-top: 20px">
                        <!-- <v-text-field
                            v-model="passkey"
                            label="Password"
                            clearable
                            color="orange"
                            :disabled="submitting" 
                            @onchange="submitError = ''"
                        ></v-text-field> -->
                    </div>
                    <div style="display: flex; flex-direction: column">
                        <template v-if="submitError != ''">
                            <v-icon color="orange" x-large>mdi-alert-circle-outline</v-icon>
                            <div v-html="submitError" style="margin-bottom: 20px"></div>
                        </template>
                        <v-btn :disabled="submitting || (passkey.length < 6)" color="orange" @click="submit">{{ registerButton }}</v-btn>
                    </div>
                </template>
                <template v-if="showThanks">
                    <div style="font-size: 30px; font-weight:bold; color: orange">Thank You!</div>
                </template>                
                <social-component />
            </div>                
        </div>
    </v-container>
</template>

<style scoped>
* {
    color: rgba(255, 255, 255, 0.78);
}
.kevin-font {
    font-family: Roboto !important;
    font-weight: 400 !important;
    margin: 0px !important;
    font-size: 1em !important;
    line-height: 1.5 !important;
}

@font-face {
    font-family: "SedanSC";
    src: url("../assets/fonts/Sedan/Sedan-Regular.ttf");
}

.custom-title {
    font-family: SedanSC;
    font-size: 2em;
}

@media (min-width: 700px) {
    .custom-title .line1 {
        font-size: 2em;
    }

    .custom-title .line2 {
        font-size: 1.1em;
        line-height: 2.5em;
    }
}

@media (max-width: 500px) {
    .custom-title .line1 {
        font-size: 2em;
    }

    .custom-title .line2 {
        font-size: 1.1em;
        line-height: 2.5em;
    }
}
</style>

<script>
/* eslint-disable */

import { mapGetters } from 'vuex';

export default {
    mounted() {
        var self = this;
        this.$nextTick(() => {
            if (self.$route.params.pass) {
                self.passkey = self.$route.params.pass;
                if (!self.passkey) {
                    self.error = "Invalid link"                    
                }
            } else {
                self.error = "Invalid link"
            }
            
            var mobileAndTabletCheck = function () {
                let check = false;
                (function (a) {
                    if (
                        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                            a
                        ) ||
                        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                            a.substr(0, 4)
                        )
                    )
                        check = true;
                })(navigator.userAgent || navigator.vendor || window.opera);
                return check;
            };

            var keplrConnect = async (addEvent) => {
                addEvent = (typeof(addEvent) == 'undefined') ? true : addEvent;

                if (mobileAndTabletCheck()) {
                    self.loading = false;
                    self.error =
                        "Mobile device is not supported<br>Please install Keplr on your Mac / PC";
                } else {
                    if (
                        !window.keplr ||
                        !window.getEnigmaUtils ||
                        !window.getOfflineSignerOnlyAmino
                    ) {
                        self.loading = false;
                        self.error = "Cannot find Keplr wallet";
                    } else {
                        try {
                            const CHAIN_ID = "secret-4";
                            await window.keplr.enable(CHAIN_ID);
                            const keplrOfflineSigner =
                                window.getOfflineSignerOnlyAmino(CHAIN_ID);
                            const signer =
                                await keplrOfflineSigner.getAccounts();
                            console.log(signer);
                            self.walletAddress = signer[0].address;
                            
                            if (addEvent) {
                                window.addEventListener("keplr_keystorechange", () => {
                                    keplrConnect(false);
                                });
                            }
                            
                        } catch (err) {
                            console.log(err.message);
                            self.error =
                                "Cannot connect to your wallet<br>Please make sure Keplr is installed properly";
                        }
                        self.loading = false;
                    }
                }
            };

            setTimeout(keplrConnect, 1000);
        });
    },
    data: () => ({
        loading: true,
        submitting: false,
        walletAddress: "",
        passkey: "",
        error: "",
        submitError: "",
        showThanks: false

    }),
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain"
        }),
        playerWidth() {
            return Math.min(560, window.innerWidth * 0.9);
        },
        shotAddress() {
            if (this.walletAddress.length > 1) {
                return (
                    this.walletAddress.slice(0, 13) +
                    "…" +
                    this.walletAddress.substr(this.walletAddress.length - 6)
                );
            }
            return "unknown";
        },
        registerButton() {
            return this.submitting ? "Please wait..." : "Submit";
        }
    },
    methods: {
        submit() {
            var self = this;

            this.submitting = true;

            var options = {
                url:  `${this.currentDomain}/api/ConfirmEntry`,
                method: 'PUT',
                headers: 
                {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                body: JSON.stringify({ "passkey": this.passkey, "walletAddress": this.walletAddress }),
            };
            this.$http(options).then((response) => {            
                self.submitting = false;
                if (response.status == 200) {
                    self.showThanks = true;                    
                } else {
                    self.submitError = "Unknown error, please try again later";
                }
            }).catch( (err) => {
                if (err.body == "") {
                    self.submitError = "Server unavailable, please try again later";
                } else {
                    self.submitError = err.body;
                }
                self.submitting = false;
            });
        }        
    },
};
</script>
