<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12" class="d-flex justify-center" style="">
                <site-logo />
            </v-col>
            <v-col cols="12" class="custom-title">
                <span class="line1">Kevin Smith</span><br>
                <span class="line2" style="color: red;">KILLROY WAS HERE</span>
            </v-col>
            
            <template v-if="!showThanks">
            <v-col  v-if="$vuetify.breakpoint.name != 'xs' " xs="1" sm="1" md="2" lg="3" xl="4"></v-col>
            <v-col xs="12" sm="10" md="8" lg="6" xl="4" class="exp-text">
                <b>VeeFriends!</b><br>
                Kevin Smith and SCRT Labs are making NFT history by minting the first full-length feature film ever,<br v-if="isMobile()"> only on Legendao.<br><br>
                Here's your chance to be part of NFT history and score a <br v-if="!isMobile()">KillRoy Was Here PFP for free!<br>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.name != 'xs' " xs="1" sm="1" md="2" lg="3" xl="4"></v-col>
            </template>

            <v-col
                cols="12"
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                "
            >
                <template v-if="error != ''">
                    <v-icon color="orange" x-large
                        >mdi-alert-circle-outline</v-icon
                    >
                    <div v-html="error" style="margin-bottom: 20px"></div>
                </template>

                <template v-if="!showThanks">
                    <v-form  id="registrationForm" onSubmit="return false;" v-model="validForm" style="min-width: 300px">
                    <div style="width: 100%">
                        <v-text-field
                            v-model="email"
                            label="Enter your email address here"
                            clearable
                            color="orange"
                            :disabled="submitting" 
                            :rules="[rules.email, rules.required]"
                            @onchange="error = ''"
                        ></v-text-field>
                    </div>
                    <div>
                        <template v-if="submitError != ''">
                            <v-icon color="orange" x-large>mdi-alert-circle-outline</v-icon>
                            <div v-html="submitError" style="margin-bottom: 20px"></div>
                        </template>
                        <v-btn :disabled="!validForm" color="orange" @click="submit">{{ registerButton }}</v-btn>
                    </div>
                    </v-form>
                </template>
                <template v-if="!showThanks">
                <!-- <v-col v-if="$vuetify.breakpoint.name != 'xs' " xs="1" sm="1" md="2" lg="3" xl="4"></v-col> -->
                <v-col xs="12" class="exp-text-part-2">
                    You'll get a confirmation email explaining what happens next.<br>
                    If you're one of the first 50 people to do this, congratulations!<br>
                    You're getting a KillRoy Was Here PFP with Gary Vaynerchuk traits dropped<br>
                    to your Secret ($SCRT) wallet with all of its perks - including,<br v-if="!isMobile()">
                    of course, the ability to access the film whenever you want!<br>
                    If you're not one of the first 50, don't worry.<br>
                    You're not left out in the dark and stormy night.<br>
                    We're giving whitelist to all of our new VeeFriends.<br>
                    That gets you 25% off public mint price.<br><br>
                    Welcome to the party, KillRoy Kids.
                </v-col>
                <!-- <v-col v-if="$vuetify.breakpoint.name != 'xs' " xs="1" sm="1" md="2" lg="3" xl="4"></v-col> -->
                </template>                
                <template v-if="showThanks">
                    <div style="font-size: 30px; font-weight:bold; color: orange">Thank You!</div>
                    <div>
                        Please check your email for further instructions
                    </div>
                </template>
                <social-component />
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
* {
    color: rgba(255, 255, 255, 0.78);
}
.kevin-font {
    font-family: Roboto !important;
    font-weight: 400 !important;
    margin: 0px !important;
    font-size: 1em !important;
    line-height: 1.5 !important;
}

@font-face {
    font-family: "SedanSC";
    src: url("../assets/fonts/Sedan/SedanSC-Regular.ttf");
}

.custom-title {
    font-family: SedanSC;
    font-size: 2em;
}

@media (min-width: 700px) {
    .custom-title .line1 {
        font-size: 2em;
    }

    .custom-title .line2 {
        font-size: 1.1em;
    }

    .exp-text {
        text-align: center;
    }

    .exp-text-part-2 {
        text-align: center;
    }
}

@media (max-width: 500px) {
    .custom-title .line1 {
        font-size: 2em;
    }

    .custom-title .line2 {
        font-size: 1.1em;
    }

    .exp-text {
        text-align: center;
    }

    .exp-text-part-2 {
        text-align: left;
    }

}


</style>

<script>
/* eslint-disable */

import { mapGetters } from 'vuex';

const ENABLE_GEO = process.env.hasOwnProperty('VUE_APP_ENABLE_GEO') ? process.env.VUE_APP_ENABLE_GEO == 1 : false;

export default {
    mounted() {
        var self = this;
        
        this.$nextTick(() => {
            self.recaptcha();
            console.log(`is mobile: ${self.isMobile()}`);
            var getLocation = () => {
                navigator.geolocation.getCurrentPosition( (position) => {
                    self.geo["latitude"] = position.coords.latitude;
                    self.geo["longitude"] = position.coords.longitude;
                    console.log(self.geo);
                }, 
                (error) => {
                    self.error = "Please approve your location<br>Just to make sure that you're in the conference";
                });
            }

            if (ENABLE_GEO) {
                console.log("Geofencing is enabled");
                if ( navigator.permissions && navigator.permissions.query) {
                    navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                        const permission = result.state;
                        if ( permission === 'granted' || permission === 'prompt' ) {
                            getLocation();
                        }
                    });
                } else if (navigator.geolocation) {
                    getLocation();
                } else {
                    self.error = "Geolocation is not supported by this browser.";
                }
            }
        });
    },
    data: () => ({
        loading: true,
        submitting: false,
        validForm: false,
        geo: {},
        email: "",
        error: "",
        submitError: "",
        showThanks: false,
        captchaToken: ""
    }),
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
            rules: "getInputRules",
        }),
        playerWidth() {
            return Math.min(560, window.innerWidth * 0.9);
        },
        registerButton() {
            return this.submitting ? "Please wait..." : "Register";
        }
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded();
            this.captchaToken = await this.$recaptcha('login');
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },

        submit() {
            var self = this;

            this.submitting = true;

            var options = {
                url:  `${this.currentDomain}/api/AddEntry`,
                method: 'POST',
                headers: 
                {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                body: JSON.stringify({ "email": this.email, geo: self.geo, captcha: this.captchaToken }),
            };
            this.$http(options).then((response) => {            
                self.submitting = false;
                if (response.status == 200) {
                    try {
                        self.error = "";
                        self.showThanks = true;
                    } catch (err) {
                        console.error(err);
                        self.error = "Unknown error, please try again later";
                    }
                } else {

                }
            }).catch( (err) => {
                console.log(err.body);
                if (err.body == "") {
                    self.error = "Server unavailable, please try again later";
                } else {
                    self.error = err.body;
                }
                self.submitting = false;
            });
        }        
    },
};
</script>
