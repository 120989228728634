import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        domain: process.env.VUE_APP_API_URL,
        
    },
    mutations: {},
    getters: {
        getCurrentDomain(state) {
            return state.domain;
        },
        getInputRules() {
            var rules = {
                required: value => !!value || "Required",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return (value == '') || pattern.test(value) || "Invalid E-Mail";
                }
            };

            return rules;
        },        
    },
    actions: {}
});