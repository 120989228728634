/* eslint-disable */

import Vue from "vue";
import VueRouter from "vue-router";
import ConfirmationView from "../views/ConfirmationView.vue";
import RegisterView from "../views/RegisterView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: RegisterView,
        meta: { title: "Kevin Smith - Registration" },
    },
    {
        path: "/register",
        name: "register",
        component: RegisterView,
        meta: { title: "Kevin Smith - Registration" },
    },
    {
        path: "/confirmation/:pass?",
        name: "confirmation",
        component: ConfirmationView,
        meta: { title: "Kevin Smith - Confirmation" },
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

const DEFAULT_TITLE = "Kevin Smith";
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;
