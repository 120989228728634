<template>
    <a href="https://legendao.io" target="_"><v-img src="@/assets/images/logo.svg" contain height="50" ></v-img></a>
</template>

<style scoped>

</style>

<script>
export default {
    name: "site-logo",

    data: () => ({}),
};
</script>
